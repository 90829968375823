.telephone {
    position: fixed;
    bottom: 50px;
    padding: 12px;
    width: 80%;
    background-color: #830000;
    color: white;
    text-align: center;
    border-radius: 10px;
    display: table;
    margin: 0, auto;
    
}


a{
    text-decoration: none;
    color: white;
    /* font-size: 2em; */
}

