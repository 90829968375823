
.home {
    min-height: 20rem;
    height: 20rem;
    top: 200px;
    padding: 48px 12px 12px 12px ;
    width: 80%;
    background-color:  #292929;;
    color: #ffd900;
    border-radius: 10px;
    display: table;
    margin: 0, auto;
    font-size: 20px;
}

ul {
    padding-inline-start: 20px;
}

.center-text {
    width: 100%;
    display: flex;
    /* justify-content: center; */
}


.content_text {
    text-align: justify;
    font-size: 15px;
    color: white;
}

@media (max-width: 286px) {
    .home {
        font-size: 15px;
    }
    .content_text {
  
        font-size: 14px;
    }
}

@media (min-height: 700px) {
    .home {
        min-height: 30rem;
        padding: 60px 12px 12px 12px ;
    }
}




@media (max-height: 600px) {
    .home {
        min-height: 18rem;
        padding: 5px 12px 12px 12px ;
    }

    .content_text {
        text-align: justify;
        font-size: 12px;
        color: white;
    }
    
}
