.price-list {
  list-style: none;
  margin: 0 auto;
  /* height: 30rem; */
  padding: 0;
  width: 90%;
  max-width: 50rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
}

/* @media (max-height: 600px) {
  .price-list{
    height: 20rem;
  }
} */
