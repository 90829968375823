.avatar {
    width: 110%;
    height: 110%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar img {
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}