.contact-container {
  width: 100%;
}

.middle-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.map-container {
    height: 15rem;
    width: 90%;
}
/* 
.contact-item {
    min-height: 19rem;
    margin: 1rem;
    width: 90%;
    height: 10 rem;
  }
   */

   .contact-item {
    margin: 10px;
    width: 80%;
    min-width: 15rem;
    height: 10 rem;
  }

/* .contact-list {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 90%;
    max-width: 50rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  } */

  .contact-list {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    /* max-width: 50rem; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
  }

.contact-item a {
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
text-decoration: none;
padding: 0.3rem;
color: white;
background: #292929;
}

.contact-item a:hover,
.contact-item a:active {
background: #ffd900;
}

.contact-item__content {
padding: 0;
}

.contact-item__image {
width: 4rem;
height: 4rem;
margin-right: 1rem;
}

.contact-item__info h2 {
font-size: 1.5rem;
margin: 0 0 0.5rem 0;
font-weight: normal;
color: #ffd900;
}

.contact-item:hover h2,
.contact-item:active h2,
.contact-item:hover h3,
.contact-item:active h3 {
color: #292929;
}

.contact-item__info h3 {
  margin: 0;
}



@media (max-height: 600px) {
 
  .contact-item {
      
      margin: 1rem;
      width: 90%;
      height: 8 rem;
    }

    .contact-item h5{
      font-size: 10px;
    }

    .contact-container {
     
      width: 100%;
    }
    .map-container {
      height: 10rem;
      width: 90%;
  }

}

@media (min-height: 700px) {
 
  .contact-item {
      
      margin: 1rem;
      width: 90%;
      height: 8 rem;
    }

    .contact-item h5{
      font-size: 10px;
    }

    .contact-container {
     
      width: 100%;
    }
    .map-container {
      height: 20rem;
      width: 90%;
  }

}

@media (min-height: 800px) {
 
  .contact-item {
      
      margin: 1rem;
      width: 90%;
      height: 8 rem;
    }

    .contact-item h5{
      font-size: 10px;
    }

    .contact-container {
     
      width: 100%;
    }
    .map-container {
      height: 25rem;
      width: 90%;
  }

}


