.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px;
    width: 100%;
    background-color: #667799;
    color: white;
    text-align: center;
}

.descriere{
    text-decoration: none;
    color: white;
    font-size: 15px;
}